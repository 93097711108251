@import 'vars';

.submenuContainer {
  width: 100%;
  background-color: #002c50;
  z-index: 10;
}

.blackWrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 80px;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: -10;
}

@media (max-width: 1365px) {
  .submenuContainer {
    position: relative;
    left: 0;
    margin-bottom: 12px;
    width: 100vw;
    div {
      div {
        padding-left: 0;
      }
    }
  }

  .blackWrapper {
    display: none;
  }
}
