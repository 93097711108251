@import '_vars';

.program {
  width: 100%;
  div {
    margin: auto;
  }
}

.programImage {
  width: 260px;
  height: 184px;
  border-radius: 30px;
  &:hover {
    cursor: pointer;
  }
}

.chipUpperText {
  @extend .semiboldP1;
  color: $white;
}

.chipLowerText {
  @extend .regularP1;
  color: $white;
}

.daysHoursContainer {
  height: 244px;
  background: $lightBlue;
}

.daysContainer {
  margin-top: 24px !important;
}

.hourTab {
  cursor: pointer;
  height: 120px;
  width: 192px;
}
.fromName {
  @extend .boldH5;
  margin-right: 0 !important;
  margin-top: 6.5px !important;
}

.programTitle {
  @extend .semiboldP1;
  text-align: left;
  margin-left: 22px !important;
  margin-top: 7px !important;
}

.hourLiveContainer {
  display: flex;

  div:first-child {
    width: 78px;
    margin-left: 16px;
    padding-top: 10px;
  }
}

.selectedProgram {
  background-color: $white;
  border-radius: 24px 24px 0px 0px;
  color: $blue;
}

.selectedProgramTitle {
  @extend .boldH4;
  color: $blue;
  cursor: pointer;
  margin-top: 5px !important;
  margin-bottom: -10px !important;
  transition: color 0.2s linear;
  &:hover {
    color: $darkBlue;
  }
}

.unselectedProgram {
  color: $white;
  &:hover {
    @extend .selectedProgram;
    opacity: 50% !important;
  }
}

.daysCarouselContainer {
  max-width: 1112px;
  margin: auto;
}

.dayProgramsCarousel {
  max-width: 1312px;
  margin: auto;
}

.programDescription {
  width: 800px;
  height: 264px;
  padding: 32px;
  column-gap: 32px;
  display: flex;
  position: relative;

  > div {
    text-align: left;
    margin: 0;
    > div {
      position: absolute;
      bottom: 48px;
    }
  }
}

.buttonPrimary {
  background-color: $blue;
  border: 2px solid transparent;
  color: $white;
  padding-left: 32px;
  transition: 0.2s linear;
  ::before {
    display: block;
    position: relative;
    top: 50%;
    left: 14px;
    transform: translateY(-50%);
    content: '';
    background-color: $white;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    transition: background-color 0.2s linear;
  }
  &:hover {
    background-color: $blue;
    color: $white;
    border: 2px solid $white;
    box-shadow: 8px 8px 16px #003464;
    ::before {
      background-color: $white;
    }
  }
  &.buttonInactive {
    background-color: $gray-100 !important;
    color: $gray-200;
    padding-left: 16px;
  }
}

.backgroundWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -10;
  background-color: rgba(0, 0, 0, 0.8);
}

@media screen and (max-width: 1365px) {
  .programImage {
    display: none;
  }
  .programDescription {
    width: 100%;
  }
}
