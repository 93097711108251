@import '_fonts';
@import '_vars';

.navWrapper,
.hideNavWrapper {
  background: $blueGradient;
  width: 100%;
  z-index: 101;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  transition: top linear 0.2s;
}

.hideNavWrapper {
  left: 0;
  top: -80px;
}

.navContainer {
  max-width: 1440px;
  height: 80px;
  padding: 0 80px;
  z-index: 1000;
  > .linkContainer {
    display: flex;
    align-items: center;
    margin: auto;
    flex-grow: 1;
    .buttonLink {
      padding-left: 0;
    }
    > .buttonLink:nth-child(7) {
      margin-left: auto;
    }
  }
  display: flex;
  align-items: center;
  margin: 0 auto;
}

.link,
.activeLink {
  @extend .regularP1;
  padding: 10px 16px;
  color: $white;
  text-transform: capitalize;
  cursor: pointer;
  display: flex;
  justify-content: center;
  transition: background-color 0.2s linear, border-radius 0.2s linear;
}
.link:hover {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 10px 16px;
  border-radius: 24px;
}
.activeLink:hover .chevron {
  transform: rotate(-180deg);
}

.chevron {
  margin-left: 8px;
  transition: transform 0.2s linear;
}

.activeLink {
  font-weight: 700;
}

.toyaLogoLink {
  width: 152px;
  height: 54px;
}

.searchContainer {
  background-color: transparent;
  height: 40px;
  border-radius: 64px;
  position: relative;
  margin-right: 32px;
}

.input,
.inputOpen {
  position: absolute;
  height: 100%;
  left: 0;
  bottom: 0;
  border: 0;
  padding: 0;
  border-radius: 64px;
  transition: width 0.3s linear;
}

.input {
  z-index: -10;
  width: 0;
  left: 10px;
}

.inputOpen {
  width: 660px;
  z-index: 1;
  padding-left: 70px;
  transition: width 0.3s linear;
}

.searchImage {
  border: 2px solid $white !important;
  border-radius: 64px !important;
  padding: 4px 8px !important;
  cursor: pointer;
  z-index: 10 !important;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: $blue;
}
.searchImageDark {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 10 !important;
  background-color: $blue;
}

.outsideContent {
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  z-index: 1000;
  > .buttonContainer {
    width: 157px;
    margin: auto;
  }
}

.buttonSecondary {
  border: 2px solid $white;
  transition: 0.2s linear;
  margin-left: auto;
  height: 40px;
  padding: 18px;
  @extend .semiboldP2;
  &:hover {
    border-width: 2px;
    background-color: $blue;
    box-shadow: 8px 8px 16px #003464;
  }
  &:active {
    background-color: $semidarkBlue;
  }
  svg {
    transform: rotate(270deg);
  }
}
.activeButtonSecondary {
  background-color: $semidarkBlue;
}
.arrowRotated {
  transform: rotate(180deg);
}

.buttonPrimary {
  background-color: $white;
  color: $blue;
  padding-left: 40px;
  height: 40px;
  transition: 0.2s linear;
  box-shadow: 2px 4px 6px rgba(0, 52, 100, 0.5);
  border: 2px solid transparent;
  @extend .semiboldP2;
  ::before {
    display: block;
    position: relative;
    top: 50%;
    left: 14px;
    transform: translateY(-50%);
    content: '';
    background-color: $blue;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    transition: background-color 0.2s linear !important;
  }
  &:hover {
    background-color: $white;
    color: $semidarkBlue;
    box-shadow: 8px 8px 16px #003464;
    ::before {
      background-color: $semidarkBlue;
    }
  }
  &:active {
    border: 2px solid $semidarkBlue;
    height: 40px;
    box-shadow: 1px 1px 6px rgba(0, 52, 100, 0.5);
    background-color: $gray-50;
    transition: all 0.2s linear !important;
  }
}

.programButton {
  width: 157px;
  height: 48px;
  background-color: $lightBlue;
  border-radius: 0px 0px 16px 16px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  border: none;
  outline: none;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 24px;
  cursor: pointer;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  transition: box-shadow 0.2s linear;
  img {
    margin-left: 4px;
  }
  &:hover {
    background-color: #5daced;
  }
  &:active {
    background-color: $blue;
  }
}

@media screen and (max-width: 1365px) {
  .navContainer {
    padding: 0 16px;
    column-gap: 80px !important;
  }
  .link,
  .activeLink {
    font-size: 14px;
    line-height: 18px;
  }
}

@media only screen and (max-width: 1025px) {
  .navContainer {
    padding: 16px;
    justify-content: space-between;
    height: 80px;
    align-items: center;
    > .linkContainer,
    .mobileLinkContainer {
      position: absolute;
      left: 0;
      z-index: 2000;
      width: 100vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      background: $blueGradient;
      padding: 16px 0;
      border-radius: 0px 0px 32px 32px;
      color: $white;
      font-family: 'Poppins', sans-serif;
      font-size: 16px;
      line-height: 24px;
      transition: top 0.5s linear;
    }
    > .linkContainer {
      top: -300vh;
      margin-left: 0;
    }
  }
  .toyaLogoLink {
    height: 42px;
    img {
      height: 42px !important;
    }
  }
  .hideNavWrapper {
    top: -80px;
  }

  .mobileLinkContainer {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2000;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background: $blueGradient;
    padding: 16px;
    border-radius: 0px 0px 32px 32px;
    color: $white;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    line-height: 24px;
    transition: top 0.5s linear;

    > a.buttonLink {
      margin-bottom: 0;
      margin-left: 16px;
      margin-right: 16px;
    }

    .buttonSecondary {
      margin-bottom: 16px;
      margin-left: 0;
      width: 100%;
    }
  }
  .link,
  .activeLink {
    margin-bottom: 30px;
    padding-left: 0;
  }

  .activeLink {
    font-weight: 700;
    margin-bottom: 12px;
  }

  .buttonPrimary {
    width: 100%;
    ::before {
      left: calc(50% - 50px);
    }
  }
  .mobileWrapper {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 16px;
    justify-content: space-between;
  }
  .searchContainer {
    display: flex;
    width: 500px;
  }
  .searchImage {
    padding: 4px 12px !important;
  }

  .inputOpen {
    width: 100%;
    padding-left: 80px;
  }

  .wrapper {
    position: absolute;
    width: 100%;
    height: 120vh;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.8);
    top: 0;
    left: 0;
  }

  .outsideContent {
    top: 80px;
  }
}

@media only screen and (max-width: 420px) {
  .navContainer {
    height: 56px;
  }
  .toyaLogoLink {
    height: 32px;
    img {
      height: 32px !important;
    }
  }
  .hideNavWrapper {
    top: -56px;
  }
  .searchContainer {
    width: 270px;
  }

  .outsideContent {
    top: 56px;
  }
}
