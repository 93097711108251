html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.carousel .control-dots .dot {
  border-radius: 50% !important;
  width: 8px !important;
  height: 8px !important;
  /* background: #c4c4c4 !important; */
  box-shadow: none !important;
  opacity: 1 !important;
  margin: 0 4px !important;
}

.carousel .control-dots .dot.selected {
  border-radius: 24px !important;
  width: 24px !important;
  height: 8px !important;
}

.control-arrow {
  opacity: 1 !important;
  background-color: none;
}
.control-arrow:hover {
  opacity: 1 !important;
  background: none !important;
}

.control-prev:before,
.control-next:before {
  border: none !important;
  background-color: none !important;
}

@media (max-width: 1365px) {
  .carousel .control-dots {
    width: 100% !important;
  }
}

@media (max-width: 1365px) {
  .errorImage {
    width: 50%;
  }
}

@media (max-width: 420px) {
  .control-arrow {
    display: none;
  }
}
