@import 'vars';

.cookiesContainer {
  width: 880px;
  padding: 24px;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: $white;
  border-radius: 16px;
  border: 1px solid #f2f2f2;
  box-shadow: 9px 9px 24px rgba(0, 0, 0, 0.25), 2px 2px 16px rgba(0, 0, 0, 0.25);
  z-index: 100;
}

.buttonsWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  button {
    outline: none;
    cursor: pointer;
  }
  button:nth-of-type(1) {
    background-color: #fff;
    margin-right: 16px;
    text-transform: uppercase;
    color: $blue;
    border: none;
    @extend .semiboldP1;
    transition: 0.2s linear;
    &:hover {
      color: $lightBlue;
    }
    &:active {
      background-color: rgba(255, 255, 255, 0.2);
      border-radius: 32px;
      padding: 12px 8px;
    }
  }
  button:nth-of-type(2) {
    background-color: $gray-600;
    padding: 8px 12px;
    box-shadow: 2px 2px 5px rgba(28, 117, 188, 0.5);
    border-radius: 32px;
    border: 2px solid transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    text-transform: uppercase;
    transition: 0.2s linear;
    &:hover {
      border: 2px solid #ffffff;
      box-shadow: 8px 8px 16px rgba(28, 117, 188, 0.5);
    }
    &:active {
      background-color: $semidarkBlue;
    }
  }
  button:nth-of-type(3) {
    background-color: $blue;
    margin-left: 10px;
    padding: 8px 12px;
    box-shadow: 2px 2px 5px rgba(28, 117, 188, 0.5);
    border-radius: 32px;
    border: 2px solid transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    text-transform: uppercase;
    transition: 0.2s linear;
    &:hover {
      border: 2px solid #ffffff;
      box-shadow: 8px 8px 16px rgba(28, 117, 188, 0.5);
    }
    &:active {
      background-color: $semidarkBlue;
    }

    img {
      width: 24px;
      height: 24px;
      transform: rotate(270deg);
      margin-left: 8px;
    }
  }
}

@media (max-width: 900px) {
  .cookiesContainer {
    width: 95vw;
    bottom: 5px;
  }
}
