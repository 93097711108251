@import 'vars';

.footerContainer {
  width: 100vw;
  min-height: 160px;
  margin-top: auto;
  background: $gray-600;
  z-index: 10;
}

.footerWrapper {
  margin: 0 auto;
  margin-top: auto;
  width: 1440px;
  padding: 32px 80px;
  display: flex;
  flex-direction: column;
}

.line {
  width: 100%;
  border: 1px solid $white;
  margin: 24px 0;
}

.logoContainer {
  display: flex;
  align-items: center;
}
.linkLogo:nth-of-type(1) .logo,
.linkLogo:nth-of-type(3) .logo {
  height: 25px;
}

.linkLogo:nth-of-type(2) .logo {
  height: 20px;
}

.linkLogo:last-of-type {
  margin-left: auto;
}

.logo {
  max-width: 136px;
  height: 32px;
  transition: filter 0.1s linear;
}

.logo:hover {
  filter: invert(56%) sepia(33%) saturate(998%) hue-rotate(167deg)
    brightness(99%) contrast(89%);
}

.link,
.linkLogo {
  @extend .regularP2;
  color: $white;
  margin-right: 32px;
  transition: color 0.2s linear;
  a {
    padding: 12px 0;
  }
}
.link:hover {
  color: $lightBlue;
}

@media screen and (max-width: 1365px) {
  .footerWrapper {
    width: calc(100vw - 16px);
  }
}

@media (max-width: 979px) {
  .footerContainer {
    width: 100%;
    position: static;
  }
  .footerWrapper {
    width: 100%;
    padding: 16px 0;
    height: auto;
    max-width: 620px;
  }

  .logoContainer {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    column-gap: 40px;
    row-gap: 26px;
  }
  .linkLogo {
    margin-right: 0;
  }

  .linkLogo:last-of-type {
    margin-left: 0;
  }

  .linkContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .link {
    width: 140px;
    margin-right: 0;
    margin-bottom: 12px;
    padding: 8px 0;
  }
}

@media (max-width: 760px) {
  .footerWrapper {
    max-width: 328px;
  }
}
