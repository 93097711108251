.container {
  /* padding: 0 2rem; */
}

.main {
  max-width: 1440px;
  min-height: 100vh;
  margin: auto;
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1365px) {
  .container {
    padding: 0;
  }
}
