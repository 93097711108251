@import 'vars';

.outsideWrapper {
  background-color: #28303d;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 30px !important;
}

.wrapper,
.headerWrapper {
  position: relative;
  width: 100%;
}

.wrapper {
  max-width: 1440px;
}

.shades {
  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    height: 100%;
    width: 184px;
    z-index: 1;
  }
  &::after {
    right: 0;
    top: 0;
  }
}

.arrowRight {
  position: absolute;
  top: 30%;
  right: 0;
  z-index: 1000;
  transform: rotate(-90deg);
  & > button {
    border: none;
    background: none;
    &:hover {
      cursor: pointer;
    }
  }
}

.arrowLeft {
  position: absolute;
  top: 30%;
  left: 0;
  z-index: 1000;
  transform: rotate(90deg);
  & > button {
    border: none;
    background: none;
    &:hover {
      cursor: pointer;
    }
  }
}

.darkBlue {
  background-color: $darkBlue;
  &::after {
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
  &::before,
  &::after {
    background: linear-gradient(90deg, #002c50 33.71%, rgba(0, 38, 69, 0) 100%);
  }
}

.dark {
  background-color: $gray-600;
  &::before {
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
  &::before,
  &::after {
    background: linear-gradient(
      270deg,
      #28303d 42.45%,
      rgba(40, 48, 61, 0) 100%
    );
  }
}

.light {
  background-color: $gray-50;
  position: relative;
  &::before,
  &::after {
    background: linear-gradient(
      90deg,
      $gray-50 30.09%,
      rgba(233, 238, 245, 0) 97.39%
    );
  }
  &::after {
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
}

.inside {
  width: 376px;
  height: 312px;
  background-color: grey;
  border-radius: 15px;
  margin: 0 15px 15px;
  p {
    margin: 0;
  }
}

.textContainer {
  width: 100vw;
}

.textWrapper {
  padding: 32px 0 32px 80px;
  color: $white;
  width: 100%;
  margin: auto;
  max-width: 1440px;
  @include semibold(24px);
}

.dark .carouselHeader {
  color: $white;
}

.light .carouselHeader {
  color: $blue;
}

.carouselHeader {
  margin: 0;
}

.carouselSpanHeader {
  @extend .regularH5;
  font-weight: 300;
  color: $ultralightBlue;
  span {
    font-weight: 600;
  }
}

@media (max-width: 1365px) {
  .textWrapper {
    width: 100%;
  }
}

//programs carousel:

.programCard,
.biggerProgramCard,
.mediumProgramCard {
  position: relative;
  height: 240px;
  width: 312px;
  box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
  overflow: hidden;
  margin-left: 16px;
  cursor: pointer;
  background-color: $white;
  text-align: left;
  display: flex;
  flex-direction: column;
}

.biggerProgramCard {
  width: 628px;
  margin-left: 24px;
}

.mediumProgramCard {
  width: 410px;
}

li:nth-of-type(1) .biggerProgramCard,
li:nth-of-type(1) .mediumProgramCard {
  margin-left: 0;
}

.info {
  position: absolute;
  top: 100%;
  opacity: 0;
  top: 0;
  transition: top 0.2s linear;
}

.programCard:hover .info,
.mediumProgramCard:hover .info,
.biggerProgramCard:hover .info {
  display: static;
  position: relative;
  top: 0;
  opacity: 1;
}
.programCardTitle {
  margin: 0;
  color: $blue;
  padding: 16px;
  @extend .boldP1;
  background-color: $white;
  min-height: 56px;
  width: 100%;
}

.imageContainer {
  height: 184px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.programCardImage {
  height: 184px;
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
}

.info {
  padding: 0 16px 16px 16px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
  row-gap: 8px;
}

.label {
  background-color: $gray-50;
  color: $gray-400;
  @extend .regularP2;
}

.bottomContainer {
  position: absolute;
  bottom: 0;
  border-radius: 0 0 16px 16px;
  background-color: $white;
  overflow: hidden;
  width: 100%;
}

@media screen and (max-width: 1365px) {
  .programCard,
  .biggerProgramCard {
    height: 184px;
    width: 248px;
    margin-left: 16px;
  }

  .mediumProgramCard {
    width: 325px;
  }
}

@media (max-width: 1025px) {
  .programCard,
  .mediumProgramCard,
  .biggerProgramCard {
    height: 184px;
    width: 248px;
    margin-left: 16px;
  }

  .submenuProgramCard {
    width: 200px;
    height: 136px;
    box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.16);
  }

  .submenuProgramCardTitle {
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
    min-height: 30px;
    padding: 8px;
  }

  li:nth-of-type(1) .biggerProgramCard,
  li:nth-of-type(1) .mediumProgramCard {
    margin-left: 16px;
  }
  .carouselSpanHeader {
    font-size: 14px;
    line-height: 21px;
    font-weight: 300;
  }
}

@media (max-width: 830px) {
  .wrapper {
    &::before,
    &::after {
      width: 124px;
      z-index: 1;
    }
  }
  .programCardTitle {
    height: auto;
    min-height: auto;
  }
}

@media (max-width: 420px) {
  .outsideWrapper {
    padding-bottom: 25px !important;
  }
  .wrapper {
    width: 100%;
    &::before,
    &::after {
      display: none;
    }
  }
  .arrowRight {
    display: none;
  }

  .arrowLeft {
    display: none;
  }

  .textWrapper {
    padding: 16px 0 16px 24px;
  }
}

.card {
  background: $blueGradient;
  width: 376px;
  height: 250px;
  p {
    color: $white;
  }
}
